import { createStore } from "vuex";

export default createStore({
    state: {
        user: JSON.parse(localStorage.getItem("user")) || null,
        token: localStorage.getItem("token") || null,
        postDetail: JSON.parse(localStorage.getItem("postDetail")) || null,

        offerData: JSON.parse(localStorage.getItem("offerData")) || null,
        PartnerOffer: JSON.parse(localStorage.getItem("partnerOfferData")) || null,

        offerModel: false,
    },
    mutations: {
        openOfferModel(state) {
            state.offerModel = true;
        },
        closeOfferModel(state) {
            state.offerModel = false;
        },
        setUser(state, user) {
            state.user = user;
        },
        setPostDetail(state, postDetail) {
            state.postDetail = postDetail;
        },
        setToken(state, token) {
            state.token = token;
        },
        saveOfferData(state, data) {
            state.offerData = data;
          },
          savePartnerOffer(state, data) {
            state.PartnerOffer = data;
          },
        logout(state) {
            state.user = null;
            state.token = null;
        },
    },
    getters: {
        isLoggedIn: (state) => state.token !== null,
        getUser: (state) => state.user,
        getPodtDeatil: (state) => state.postDetail,
        getToken: (state) => state.token,
    },
    actions: {
        
        login({ commit }, { user, token }) {
            commit("setUser", user);
            commit("setToken", token);
        },
        postDetail({commit},{postDetail}){
            commit("setPostDetail", postDetail);
        },
        logoutUser({ commit }) {
            commit("logout");
        },
       
    },
    modules: {},
});
