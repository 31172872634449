// import { createApp } from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store";

// import { toast } from "vue3-toastify";
// import "vue3-toastify/dist/index.css";

// import VueMask from '@devindex/vue-mask'; 

// import {LoadingPlugin} from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/css/index.css';


// import "./validation/validation.js"


// import { initializeApp } from "firebase/app";
// import { getMessaging,onMessage } from "firebase/messaging";
// const firebaseConfig = {
//   apiKey: "AIzaSyBpt1exIQ6WZq1ZxxNn9RIbNOx-O99wgRM",
//   authDomain: "todo-9222d.firebaseapp.com",
//   projectId: "todo-9222d",
//   storageBucket: "todo-9222d.appspot.com",
//   messagingSenderId: "487346014799",
//   appId: "1:487346014799:web:c7119aa41810ae5b13f83a",
//   measurementId: "G-2V1TXXFJ13"
// };

// initializeApp(firebaseConfig);

// const messaging = getMessaging();

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
// });

// import { createI18n } from 'vue-i18n';
// import EN from "../src/locale/en.json";
// import DE from "../src/locale/de.json";
// const getDefaultLanguage = () => {
//   return localStorage.getItem('preferredLanguage') || 'en';
// };
// const i18n = createI18n({
//   locale: getDefaultLanguage(),
//   messages:{
//     en:EN,
//     de:DE,
//   }
// });
// import CoreuiVue from "@coreui/vue";
// import "@coreui/coreui/dist/css/coreui.min.css";

// import { library } from "@fortawesome/fontawesome-svg-core";
// import {
//   faCheck,
//   faClose,
//   faComments,
//   faCrosshairs,
//   faEdit,
//   faEllipsisV,
//   faFile,
//   faFileContract,
//   faLocationCrosshairs,
//   faLocationPin,
//   faPencil,
//   faPlus,
//   faSackDollar,
//   faShield,
//   faStar,
//   faTrash,
//   faUser,
//   faUserCircle,
//   faPaperPlane
// } from "@fortawesome/free-solid-svg-icons";
// import {
//   faFacebook,
//   faTwitter,
//   faInstagram
// } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { faEye } from "@fortawesome/free-regular-svg-icons";
// library.add(
//   faUserCircle,
//   faSackDollar,
//   faUser,
//   faComments,
//   faLocationCrosshairs,
//   faShield,
//   faFileContract,
//   faPencil,
//   faLocationPin,
//   faPlus,
//   faClose,
//   faEye,
//   faTrash,
//   faCheck,
//   faStar,
//   faEdit,
//   faTrash,
//   faCrosshairs,
//   faEllipsisV,
//   faFile,
//   faPaperPlane,
//   faFacebook,
//   faTwitter,
//   faInstagram
// );

// const vueApp = createApp(App);
// vueApp.component("font-awesome-icon", FontAwesomeIcon);
// vueApp.use(router);
// vueApp.use(store);
// vueApp.use(VueMask);
// vueApp.use(i18n);
// vueApp.use(CoreuiVue);
// vueApp.use(LoadingPlugin);
// vueApp.config.globalProperties.$toastr = toast;
// vueApp.mount("#app");


import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Toster
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import VueMask from '@devindex/vue-mask'; // <-- ADD THIS LINE
// Vue Loader
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

// Validation
import "./validation/validation.js"

// Import FireBase
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBpt1exIQ6WZq1ZxxNn9RIbNOx-O99wgRM",
  authDomain: "todo-9222d.firebaseapp.com",
  projectId: "todo-9222d",
  storageBucket: "todo-9222d.appspot.com",
  messagingSenderId: "487346014799",
  appId: "1:487346014799:web:c7119aa41810ae5b13f83a",
  measurementId: "G-2V1TXXFJ13"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Check if the browser supports Firebase Messaging
isSupported().then((supported) => {
  if (supported) {
    const messaging = getMessaging(app);

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
    });
  } else {
    console.log('Firebase Messaging is not supported in this browser.');
  }
}).catch((error) => {
  console.error("Error checking Firebase Messaging support: ", error);
});

import { createI18n } from 'vue-i18n';
import EN from "../src/locale/en.json";
import DE from "../src/locale/de.json";

const getDefaultLanguage = () => {
  return localStorage.getItem('preferredLanguage') || 'en';
};

const i18n = createI18n({
  locale: getDefaultLanguage(),
  messages: {
    en: EN,
    de: DE,
  }
});

// Vendor Import Global
import CoreuiVue from "@coreui/vue";
import "@coreui/coreui/dist/css/coreui.min.css";

// Fontawesome
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faClose,
  faComments,
  faCrosshairs,
  faEdit,
  faEllipsisV,
  faFile,
  faFileContract,
  faLocationCrosshairs,
  faLocationPin,
  faPencil,
  faPlus,
  faSackDollar,
  faShield,
  faStar,
  faTrash,
  faUser,
  faUserCircle,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";

library.add(
  faUserCircle,
  faSackDollar,
  faUser,
  faComments,
  faLocationCrosshairs,
  faShield,
  faFileContract,
  faPencil,
  faLocationPin,
  faPlus,
  faClose,
  faEye,
  faTrash,
  faCheck,
  faStar,
  faEdit,
  faTrash,
  faCrosshairs,
  faEllipsisV,
  faFile,
  faPaperPlane,
  faFacebook,
  faTwitter,
  faInstagram
);

const vueApp = createApp(App);
vueApp.component("font-awesome-icon", FontAwesomeIcon);
vueApp.use(router);
vueApp.use(store);
vueApp.use(VueMask);
vueApp.use(i18n);
vueApp.use(CoreuiVue);
vueApp.use(LoadingPlugin);
vueApp.config.globalProperties.$toastr = toast;
vueApp.mount("#app");
// createApp(App).use(store).use(router).mount('#app')
