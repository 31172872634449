import { defineRule } from 'vee-validate';


defineRule('required', (value) => {
  if (!value || !value.length) {
      return `is required`;
  }
  return true;
});
defineRule('selectRequired', (value) => {
    if (!value) {
      return 'This select is Required';
    }
    return true;
  });
defineRule('email', value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }
    // Check if email

    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
        return 'field must be a valid';
    }

    return true;
});
defineRule('minLength', (value, [limit]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
        return true;
    }
    if (value.length < limit) {
        return `field must be at least ${limit} characters`;
    }
    return true;
});
defineRule('confirmed', (value, [target]) => {
    const targetValue = document.querySelector(`input[name="${target}"]`).value;
    //console.log(value+' - '+[target]+' - '+targetValue);
    if (value === targetValue) {
        return true;
    }
    return 'does not match with your password.';
});

defineRule('checkboxRequired', (value) => {
    if (!value) {
      return 'This checkbox field is required.';
    }
    return true;
  });
  defineRule('radioRequired', (value) => {
    if (!value) {
      return 'This Check is required.';
    }
    return true;
  });

  defineRule('test', (value) => {
    if (!value) {
      return 'This Check is required.';
    }
    return true;
  });