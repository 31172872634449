import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/job-detail/:id",
    name: "job-detail",
    component: () => import("../views/JobDetail.vue"),
    meta: {
      title: "Job Details",
    },
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/Jobs.vue"),
    meta: {
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/Account.vue"),
    meta: {
      requiresAuth: true,
      title: "Account",
    },
  },
  {
    path: "/profile-settings",
    name: "profilesettings",
    component: () => import("../views/ProfileSettings.vue"),
    meta: {
      requiresAuth: true,
      title: "Profile Settings",
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/Payments.vue"),
    meta: {
      requiresAuth: true,
      title: "Payments",
    },
  },
  {
    path: "/address",
    name: "address",
    component: () => import("../views/Address.vue"),
    meta: {
      title: "Address",
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import("../views/SignUp.vue"),
    meta: {
      title: "Sign Up",
    },
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../views/SignIn.vue"),
    meta: {
      title: "Sign In",
    },
  },
  {
    path: "/forget-password",
    name: "forgetpassword",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/verify-otp",
    name: "verify-otp",
    component: () => import("../views/VerifyOTP"),
    meta: {
      title: "Verify OTP",
    },
  },
  {
    path: "/confirm-password",
    name: "confirmpassword",
    component: () => import("../views/ConfirmPassword.vue"),
    meta: {
      title: "Confirm Password",
    },
  },
  {
    path: "/packages",
    name: "packages",
    component: () => import("../views/Packages.vue"),
    meta: {
      title: "Packages",
    },
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      title: "Profile",
    },
  },
  {
    path: "/add-job",
    name: "addJob",
    component: () => import("../views/AddJob.vue"),
    meta: {
      requiresAuth: true,
      title: "Add Job",
    },
  },
  {
    path: "/edit-job/:id",
    name: "editJob",
    component: () => import("../views/EditJob.vue"),
    meta: {
      requiresAuth: true,
      title: "Edit Job",
    },
  },
  {
    path: "/documents",
    name: "documents",
    component: () => import("../views/Documents.vue"),
    meta: {
      requiresAuth: true,
      title: "Documents",
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      requiresAuth: true,
      title: "Notifications",
    },
  },
  {
    path: "/review",
    name: "review",
    component: () => import("../views/Review.vue"),
    meta: {
      requiresAuth: true,
      title: "Review",
    },
  },
  {
    path: "/all-review/:id",
    name: "all-review",
    component: () => import("../views/ReviewList.vue"),
    meta: {
      requiresAuth: true,
      title: "All Review",
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/Chat.vue"),
    meta: {
      requiresAuth: true,
      title: "Chat",
      hideHeaderFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  document.title = `${to.meta.title} | Joply`;
  const user = JSON.parse(localStorage.getItem("user"));
  const isUser = user && user.role === "user";

  if (
    requiresAuth &&
    !localStorage.getItem("user") &&
    !localStorage.getItem("isLogin")
  ) {
    next({ name: "signin" });
  } else if (isUser && to.name === "home") {
    next({ name: "jobs" });
  } else {
    next();
  }
});
export default router;
