<template>
  <section
    :class="{
      'app-layout-wrapper': true,
      'd-flex': !hideHeaderFooter,
      'flex-direction-col': !hideHeaderFooter,
      'height-100vh': !hideHeaderFooter,
    }"
  >
    <!-- Header -->
    <Header v-if="!hideHeaderFooter"></Header>
    <!-- Body Content -->
    <router-view />
    <!-- Footer -->
    <Footer v-if="!hideHeaderFooter"></Footer>
  </section>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "AppView",
  setup() {},
  components: {
    Header,
    Footer,
  },
  computed: {
    hideHeaderFooter() {
      return this.$route.meta.hideHeaderFooter || false;
    },
  },
};
</script>

<style>
:root {
  --primaryColor: #04AFEF;
  --primaryFont: "Oswald", sans-serif;
  --SecondaryFont: "Outfit", sans-serif;
}
.unstyled-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.Toastify__toast-body {
  font-family: var(--primaryFont) !important;
  text-transform: capitalize;
  color: #000;
}
.flex-direction-col {
  flex-direction: column;
}
.height-100vh {
  height: 100vh;
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
button:active {
  outline: none !important;
  border-color: unset !important;
}
button:hover {
  outline: none !important;
  border-color: unset !important;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px !important;
}

/* Deatil Page Slider Css */
.job-banner-slider .swiper-pagination-fraction {
  display: inline-block;
  left: 50%;
  width: unset;
  transform: translateX(-50%);
  background: var(--primaryColor);
  color: #fff;
  font-family: var(--primaryFont);
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 100px;
  background-image: linear-gradient(
    135deg,
    var(--primaryColor),
    var(--primaryColor)
  );
}
.job-banner-slider.home-banner .swiper-pagination-fraction {
  display: inline-block;
  left: unset;
  right: 20px;
  width: unset;
  background: var(--primaryColor);
  color: #fff;
  font-family: var(--primaryFont);
  font-weight: 600;
  padding: 5px 20px;
  border-radius: 100px;
  background-image: linear-gradient(
    135deg,
    var(--primaryColor),
    var(--primaryColor)
  );
}

.offcanvas-title {
  font-family: var(--primaryFont);
  font-size: 24px;
  color: #000;
}
.price-dropdown select {
  display: inline-block !important;
  width: unset;
  background-color: transparent !important;
  font-family: var(--primaryFont);
  border: 2px solid #fff;
  border-radius: 100px;
  color: #fff;
}
.price-dropdown select:focus {
  box-shadow: none !important;
  border-color: none !important;
}
.price-dropdown select option {
  background: var(--primaryColor);
  color: #fff;
}
.service-dropDown-wrapper select {
  padding: 14px !important;
  font-family: var(--SecondaryFont) !important;
  background: #d9d9f9 !important;
  font-weight: 500;
}
.service-dropDown-wrapper select:focus {
  box-shadow: none;
  border-color: unset !important;
}

.header-profile-wrapper .dropdown-toggle {
  padding: 0px;
  border: none !important;
}
.header-profile-wrapper .dropdown-toggle:focus,
.header-profile-wrapper .dropdown-toggle:hover,
.header-profile-wrapper .dropdown-toggle:active {
  outline: none !important;
  border-color: unset !important;
  border: none !important;
}
.header-profile-wrapper .dropdown-menu {
  font-family: var(--primaryFont);
  padding: 7px !important;
  border: none;
  border-radius: 7px;
}
.header-profile-wrapper .dropdown-menu .dropdown-item {
  color: #000;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  padding: 7px 7px !important;
  text-transform: uppercase;
  border-radius: 7px;
}
.header-profile-wrapper .dropdown-menu .dropdown-item:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.header-profile-wrapper .dropdown-toggle::after {
  border-top: 0.3em solid #fff;
}
.address-picker-wrapper .offcanvas-header {
  padding: 10px 10px;
  background: var(--primaryColor);
}
.address-picker-wrapper .offcanvas-title {
  font-family: var(--primaryFont);
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
}
.address-picker-wrapper .offcanvas-header .btn-close {
  filter: invert(1);
  opacity: 1;
}
.address-picker-wrapper .offcanvas-body {
  padding: 0px 0px;
}
.card-action-wrapper .dropdown-toggle::after {
  display: none;
}
.card-action-wrapper {
  position: absolute;
  top: 17px;
  right: 10px;
}
.pending-card-actions .pending-actionBtn {
  background: var(--primaryColor);
  border: none;
  color: #fff;
  border-radius: 100px;
}
.pending-card-actions .pending-actionBtn:focus,
.pending-card-actions .pending-actionBtn:active {
  background: var(--primaryColor);
  color: #fff;
}
.pending-card-actions .pending-actionBtn:hover {
  background: var(--primaryColor);
  color: #fff;
}
.pending-card-actions .dropdown-menu {
  padding: 10px;
  border: none;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
}
.pending-card-actions .dropdown-menu .dropdown-item {
  font-family: var(--primaryFont);
  color: #000;
  font-weight: 400;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
}
.pending-card-actions .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: var(--primaryColor);
  transition: all 0.3s ease-in-out;
}
.vti__dropdown {
  font-family: var(--primaryFont);
}
.vti__dropdown.open {
  background-color: transparent !important;
}
.vti__dropdown:hover {
  background-color: transparent !important;
}
.vti__input {
  background: transparent !important;
}
.multiselect-tags-search {
  background: transparent;
  font-family: var(--primaryFont);
}
.multiselect-option {
  font-family: var(--primaryFont);
}
.multiselect-tag-wrapper {
  font-family: var(--primaryFont);
}
.notification-toster .toast {
  background: var(--primaryColor);
  border: none;
}
.notification-toster .toast-header {
  background: #00aeef;
}
.notification-toster .toast-header img {
  width: 24px;
  margin-right: 5px;
}
.notification-toster .toast-header span {
  text-transform: uppercase;
  color: #fff;
  position: relative;
  top: 0px;
  font-family: var(--primaryFont);
  font-size: 16px;
}
.notification-toster .toast-body {
  color: #fff;
  font-family: var(--primaryFont);
  font-weight: 400;
}
.notification-toster .toast-body button {
  background: transparent;
  color: #00aeef;
  text-transform: uppercase;
  border: none;
  padding: 0px;
}

.notification-toster .toast-body span {
  font-size: 16px;
  text-transform: uppercase;
}
.notification-toster .toast-body button:focus {
  outline: none;
  box-shadow: none;
}
.nav-signup-btn {
  color: #fff;
  background-image: linear-gradient(135deg, #00aeef, #00aeef);
  font-size: 15px;
  padding: 9px 38px;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  transition: 0.4s all;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--primaryFont);
}
.nav-signup-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -80px;
  width: 100px;
  height: 50px;
  transform: skew(-25deg);
  animation: main_anim 3s infinite ease-in-out;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
@keyframes main_anim {
  0% {
    left: -120px;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
.testimonials-slider-wrapper .swiper-pagination-bullet-active{
  background: var(--primaryColor) !important;
}

</style>
