<template>
  <section class="app-footer-wrapper mt-auto">
    <CContainer>
      <CRow>
        <CCol lg="3" class="mb-4 mb-lg-0">
          <div class="footer-logo-info-wrapper">
            <div class="footer-logo">
              <img :src="footerLogo" alt="footer-logo" class="img-fluid" />
              <p class="mb-0 mt-2">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry
              </p>
              <ul class="socail-media-list mt-3">
                <li>
                  <a href="#"
                    ><font-awesome-icon icon="fa-brands fa-facebook"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><font-awesome-icon icon="fa-brands fa-twitter"
                  /></a>
                </li>
                <li>
                  <a href="#"
                    ><font-awesome-icon icon="fa-brands fa-instagram"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" xs="6" class="mb-4 mb-lg-0">
          <div class="footer-nav">
            <h4 class="mb-3">Quick Links</h4>
            <ul>
              <li><router-link to="#">Home</router-link></li>
              <li><router-link to="#">About us</router-link></li>
              <li><router-link to="#">Faq</router-link></li>
              <li><router-link to="#">Contact Us</router-link></li>
            </ul>
          </div>
        </CCol>
        <CCol lg="2" xs="6" class="mb-4 mb-lg-0">
          <div class="footer-nav">
            <h4 class="mb-3">Help</h4>
            <ul>
              <li><router-link to="#">Privacy Policy</router-link></li>
              <li><router-link to="#">Terms of Use</router-link></li>
            </ul>
          </div>
        </CCol>
        <CCol lg="4" class="mb-4 mb-lg-0">
          <div class="footer-nav">
            <h4 class="mb-3">Get In Touch</h4>
            <ul>
              <li>184 Main Rd E, St Albans VIC 3021, Germany</li>
              <li>+4920487017951</li>
              <li>info@joply.com</li>
            </ul>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <section class="copyright-wrapper">
    <CContainer>
      <CRow>
        <CCol lg="12">
          <div class="app-footer-content-wrapper">
            <div class="app-footer-content text-center">
              <p class="mb-0">
                &copy; Copyright <router-link to="#">JOPLY, 2024</router-link>. All
                Right Reserved
              </p>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <div style="position: fixed; bottom: 10px; right: 10px" v-if="toster">
    <div class="notification-toster">
      <CToast :autohide="true" @close="handleClose" visible>
        <CToastHeader closeButton>
          <img :src="notificationLogo" alt="notofication logo" />
          <span class="me-auto">{{
            singleNotification.notification.title
          }}</span>
        </CToastHeader>
        <CToastBody
          ><span class="d-block">{{
            singleNotification.notification.body
          }}</span>
          <button @click="PostRedirect()">Go to Post</button></CToastBody
        >
      </CToast>
    </div>
  </div>
</template>

<script>
import { CContainer, CRow, CCol } from "@coreui/vue";
import { getMessaging, onMessage } from "firebase/messaging";
import notificationLogo from "../assets/favicon.png";
import footerLogo from "../assets/app-logo-white.png";
export default {
  name: "FooterComponent",
  setup() {
    return {
      notificationLogo,
      footerLogo,
    };
  },
  data() {
    return {
      toster: false,
      singleNotification: "",
      RefpostID: "",
    };
  },
  mounted() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this.toster = true;
      console.log("Nofication:", payload);
      this.singleNotification = payload;
      const notificationData = JSON.parse(this.singleNotification.data.data);
      this.RefpostID = notificationData.post;
    });
  },
  components: {
    CContainer,
    CRow,
    CCol,
  },
  methods: {
    handleClose() {
      this.toster = false;
    },
    PostRedirect() {
      const routerName = this.$route.name;
      if (routerName == "job-detail") {
        window.location.reload();
      } else {
        this.$router.push({ path: `/job-detail/${this.RefpostID}` });
      }
    },
  },
};
</script>
<style scoped>
.app-footer-wrapper {
  /* margin-top: 30px; */
  background: #0386B8;
  padding: 60px 0px 30px 0px;
}
.app-footer-content p {
  color: #fff;
  font-family: var(--SecondaryFont);
  font-size: 16px;
}
.app-footer-content p a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}
.footer-logo img{
  width: 120px;
}
.footer-logo-info-wrapper p {
  font-family: var(--SecondaryFont);
  color: #fff;
  font-weight: 400;
  font-size:20px;
}
.socail-media-list li {
  display: inline-block;
  margin-right: 10px;
}
.socail-media-list li a {
  display: flex;
  width: 32px;
  height: 32px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: var(--primaryColor);
  border-radius: 5px;
}
.footer-nav h4 {
  color: #fff;
  font-size: 34px;
  font-family: var(--primaryFont);
}
.footer-nav ul li {
  margin-bottom: 8px;
}
.footer-nav ul li a {
  color: #fff;
  font-size: 18px;
  font-family: var(--SecondaryFont);
  font-weight: 400;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.footer-nav ul li {
  color: #fff;
  font-size: 18px;
  font-family: var(--SecondaryFont);
  font-weight: 400;
  text-decoration: none;
}
.footer-nav ul li a:hover {
  padding-left: 5px;
}
.copyright-wrapper {
  background: #023A4F;
  padding: 20px 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .app-footer-wrapper {
    padding: 60px 0px 0px 0px;
  }
  .copyright-wrapper{
    padding: 10px 0px;
  }
  .footer-nav h4{
    font-size:30px;
  }
}
</style>
